import Link from '@/components/base/elements/Link/Link';
import {useTranslations} from '@/hooks/useTranslations';
import {twMerge} from '@/stylesheets/twMerge';

type MinimalFooterProps = {
  className?: string;
};

export default function MinimalFooter({className}: MinimalFooterProps) {
  const {t, localizePath} = useTranslations();

  const footerLinks = [
    {
      text: t('footer:termsOfService'),
      href: localizePath('/legal/terms'),
    },
    {
      text: t('footer:privacyPolicy'),
      href: localizePath('/legal/privacy'),
    },
    {
      text: t('footer:sitemap'),
      href: localizePath('/sitemap'),
    },
  ];

  return (
    <footer className={twMerge('pb-5xl bg-color-[#F1FFF9]', className)}>
      <nav>
        <ul className="container flex justify-end">
          {footerLinks.map(({href, text}, index) => (
            <li key={index} className="ml-3 sm:ml-8 md:ml-12 lg:ml-28">
              <Link size="small" arrow={false} href={href}>
                {text}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </footer>
  );
}
